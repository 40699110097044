// The HTTP (non-S) URLs are only for local development.
// noinspection HttpUrlsUsage
export const getConfig = () => ({
  baseUrl:
    process.env.REACT_APP_BASE_URL || 'https://checkout-staging.basworld.com',
  account: {
    url:
      process.env.REACT_APP_ACCOUNT_URL ||
      'https://authentication.stg.platform.basworld.com',
  },
  newAuth: {
    url:
      process.env.REACT_APP_NEW_AUTH_URL || 'https://dev.auth.bff.basworld.com',
  },
  environment: process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV,
  esb: {
    url: process.env.REACT_APP_ESB_URL || 'https://staging-api.bastrucks.com',
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  static: {
    url: process.env.REACT_APP_STATIC_URL || 'https://static.basworld.com',
  },
  vehicle: {
    url:
      process.env.REACT_APP_VEHICLE_URL ||
      'https://staging-vehicle.api.basworld.com',
  },
  web: {
    url: process.env.REACT_APP_WEB_URL || 'https://staging-www.basworld.com',
    documentsUrl:
      process.env.REACT_APP_WEB_DOCUMENT_URL || 'https://www.basworld.com',
    cookiesDomain: process.env.REACT_APP_WEB_COOKIES_DOMAIN || 'basworld.com',
  },
  google: {
    recaptchaSiteKey:
      process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
      '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  },
  log: {
    path: '/gateway/log',
  },
  gitlab: {
    apiUrl:
      process.env.REACT_APP_GITLAB_URL || 'https://gitlab.bastrucks.com/api/v4',
    projectId: process.env.REACT_APP_GITLAB_PROJECT_ID || '186',
    newAuthenticationFlag: 'implement-authentication',
    gitlabToken:
      process.env.REACT_APP_GITLAB_API_TOTKEN || 'FzKnrvDVguHNxEXexWz8',
  },
  auth: {
    url: process.env.REACT_APP_AUTH_URL || 'https://dev.auth.basworld.com',
  },
  isNewAuthentication: process.env.REACT_APP_IS_NEW_AUTHENTICATION === 'true',
});
