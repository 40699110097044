import { Injector } from './Injector';
import { AccountService, JwtData } from './AccountService';
import { JwtResponse, WebService } from './WebService';
import { AuthStore } from '../store/auth';
import { ContactStore } from '../store/contact';
import { deleteCookie } from '../utils/cookie';
import { logger } from '../logger';
import { NewAuthService } from './NewAuthService';
import { FeatureFlagService } from './FeatureFlagService';

export class NoJwtDataError extends Error {}

export class AuthService {
  public static readonly NAME = 'AuthService';

  private readonly accountService: AccountService;
  private readonly webService: WebService;
  private readonly newAuthService: NewAuthService;
  private readonly featureFlagService: FeatureFlagService;

  public constructor(injector: Injector) {
    this.accountService = injector.resolve(AccountService);
    this.webService = injector.resolve(WebService);
    this.newAuthService = injector.resolve(NewAuthService);
    this.featureFlagService = injector.resolve(FeatureFlagService);
  }

  public async createAnonAccount(
    auth: AuthStore,
    contact: ContactStore
  ): Promise<boolean> {
    let anonToken: JwtData;

    try {
      logger.debug('Creating anonymous session.');
      deleteCookie('tokenExpiryTime');
      anonToken = await this.accountService.createAnonToken();
    } catch (e) {
      logger.error('Error while creating anonymous session.');
      return false;
    }

    auth.setTokens(
      anonToken.access_token,
      anonToken.refresh_token,
      anonToken.expires_in
    );

    contact.setNoContact();

    return true;
  }

  public async setUserSession(
    auth: AuthStore,
    contact: ContactStore,
    flagStatus: boolean
  ): Promise<boolean> {
    let jwtResponse: JwtResponse | undefined;
    try {
      jwtResponse = await this.webService.tokenFromSession();
      if (flagStatus && jwtResponse) {
        jwtResponse = {
          jwtData: {
            access_token: 'fake_access_token',
            refresh_token: 'fake_refresh_token',
          },
          authenticated: jwtResponse.authenticated,
          id: jwtResponse.id,
          name: jwtResponse.name,
          email: jwtResponse.email,
        };
      }
    } catch (e) {
      logger.error('Error while getting session data from web.');
      return false;
    }

    // No JWT data at all
    if (!jwtResponse) {
      throw new NoJwtDataError('Response does not contain any JWT data.');
    }

    // This is a known bug in web:
    // in some scenarios getting the session data will return an authenticated session,
    // but won't actually return JWT data.
    if (
      jwtResponse.authenticated &&
      !jwtResponse.jwtData?.access_token &&
      !flagStatus
    ) {
      throw new NoJwtDataError(
        'Authenticated session does not contain JWT data.'
      );
    }

    const isValidated = this.validateAndStoreJwtData(
      jwtResponse,
      auth,
      contact,
      flagStatus
    );
    if (!isValidated && !flagStatus) {
      try {
        return await this.createAnonAccount(auth, contact);
      } catch (e) {
        logger.error('Error while creating anonymous account.');
        return false;
      }
    }
    return true;
  }

  private validateAndStoreJwtData(
    jwtResponse: JwtResponse,
    auth: AuthStore,
    contact: ContactStore,
    flagStatus: boolean
  ): boolean {
    if (!jwtResponse?.jwtData?.access_token && !flagStatus) {
      return false;
    }
    if (!flagStatus) {
      auth.setTokens(
        jwtResponse.jwtData.access_token,
        jwtResponse.jwtData.refresh_token,
        jwtResponse.jwtData.expires_in
      );
    }

    if (jwtResponse?.name && jwtResponse?.email) {
      const names = jwtResponse.name.split(' ');
      logger.debug('Storing contact info from session.');
      contact.setContact({
        active: jwtResponse.authenticated,
        email: jwtResponse.email,
        firstName: names[0],
        lastName: names[1] || '',
        id: jwtResponse.id,
      });
    } else {
      logger.debug('No contact info returned from session.');
      contact.setNoContact();
    }
    return true;
  }
}
