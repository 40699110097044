import create from 'zustand';
import { Company, EsbService, Prices, Ticket } from '../services/EsbService';
import { VehicleService } from '../services/VehicleService';
import { logger } from '../logger';

export interface TicketStore {
  loading: boolean;

  ticket?: Ticket;
  assertTicket: () => Ticket;
  assertCompany: () => Company;
  loadTicket: (
    esb: EsbService,
    id: string,
    language: string,
    vehicleService: VehicleService
  ) => Promise<void>;
  reloadTicket: (esb: EsbService, language: string) => Promise<void>;

  prices?: Prices;
  assertPrices: () => Prices;
  loadPrices: (esb: EsbService, language: string) => Promise<void>;
  loadingPrices: boolean;

  assignCompany: (esb: EsbService, company: Company) => Promise<void>;
  setAssignedCompany: (company: Company) => void;
  setAssignedDelivery: (company: Company) => void;
}

export const useTicketStore = create<TicketStore>((set, get) => ({
  loading: false,
  loadingPrices: false,

  assertTicket: () => {
    const ticket = get().ticket;
    if (!ticket || !ticket.id) {
      throw new Error(
        'Cannot assert ticket when no ticket is present in the store.'
      );
    }
    return ticket;
  },
  assertCompany: () => {
    const company = get().assertTicket().company;
    if (!company) {
      throw new Error(
        'Cannot assert company when no company is present in the store.'
      );
    }
    return company;
  },
  loadTicket: async (esb, id, language, vehicleService) => {
    if (get().loading) {
      logger.warning('loadTicket called while ticket is already being loaded.');
      return;
    }

    set({ loading: true });
    const [ticket, prices] = await Promise.all([
      esb.getTicket(id),
      esb.getPrices(id, language),
    ]);

    const vehicleImage = await vehicleService.getVehicleImage(
      ticket.vehicle.id
    );
    ticket.vehicle.vehicleImageUrl = vehicleImage?.data.url;
    set({ ticket, prices, loading: false });
  },
  reloadTicket: async (esb, language) => {
    const id = get().assertTicket().id;
    const [ticket, prices] = await Promise.all([
      esb.getTicket(id),
      esb.getPrices(id, language),
    ]);
    set({ ticket, prices });
  },

  assertPrices: () => {
    const prices = get().prices;
    if (!prices) {
      throw new Error(
        'Cannot assert prices when no prices are present in the store.'
      );
    }
    return prices;
  },
  loadPrices: async (esb, language) => {
    set({ loadingPrices: true });
    const prices = await esb.getPrices(get().assertTicket().id, language);
    set({ prices, loadingPrices: false });
  },

  assignCompany: async (esb, company) => {
    // First set the ticket data, since the ESB call is too slow for instant feedback.
    get().setAssignedCompany(company);
    await esb.setCompanyAddress(
      get().ticket?.id || '',
      company.contactId,
      company.id
    );
  },
  setAssignedCompany: (company) => {
    const ticket = get().assertTicket();
    const newTicketData: Ticket = { ...ticket, company };
    set({ ticket: newTicketData });
  },
  setAssignedDelivery: (company) => {
    const ticket = get().assertTicket();
    ticket.deliveryAddress = company;
    set({ ticket });
  },
}));
