import { HttpLogger, Logger, LoggerInterface } from '@bas-world/logjs';
import { getConfig } from './config';

const logOptions = {
  component: 'checkout',
  environment: getConfig().environment ?? 'development',
};

export const logger: LoggerInterface =
  process.env.NODE_ENV === 'test'
    ? new Logger(logOptions)
    : new HttpLogger({
        ...logOptions,
        logEndpoint: `${getConfig().web.url}${getConfig().log.path}`,
      });
