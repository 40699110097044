import create, { StoreApi } from 'zustand';
import { getCookie, setCookie } from '../utils/cookie';

export interface AuthStore {
  isNewAuth: boolean;
  loading: boolean;
  setLoading: () => void;
  setIsNewAuth: (isNewAuth: boolean) => void;
  accessToken?: string;
  refreshToken?: string;
  expiryTime?: Date;
  expiresIn?: number;
  setTokens: (
    accessToken: string,
    refreshToken?: string,
    expiresIn?: number
  ) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  loading: false,
  isNewAuth: false,
  setLoading: () => {
    set({ loading: true });
  },
  setIsNewAuth: (isNewAuth) => {
    set({ isNewAuth: isNewAuth });
  },
  setTokens: (accessToken, refreshToken, expiresIn) => {
    const expiryTimeCookie = getCookie('tokenExpiryTime');
    let expiryTime;

    if (expiryTimeCookie) {
      expiryTime = new Date(expiryTimeCookie);
    } else {
      expiryTime = new Date(Date.now() + (expiresIn || 1800) * 1000);
      setCookie({
        name: 'tokenExpiryTime',
        value: expiryTime.toString(),
      });
    }
    set({ accessToken, refreshToken, expiresIn, loading: false, expiryTime });
  },
}));

// For usage with the Injector, e.g.:
// const auth = injector.resolve(authStore);
// This gives you an AuthStoreApi.
export const authStore = (): AuthStoreApi => useAuthStore;

export type AuthStoreApi = StoreApi<AuthStore>;
