import { ApiService } from './ApiService';
import { getConfig } from '../config';

export class NewAuthService {
  public static readonly NAME = 'NewAuthService';

  private readonly api: ApiService;

  public constructor() {
    this.api = new ApiService(getConfig().newAuth.url);
  }

  public getToken(
    authKey: string
  ): Promise<{ access_token: string; id_token: string }> {
    return this.api.jsonPost('/v1/admin/tokens', { auth_key: authKey });
  }
}
