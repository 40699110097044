import React, { lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useAuthStore } from './store/auth';
import { useService } from './hooks/useService';
import { AuthService, NoJwtDataError } from './services/AuthService';
import { LoadingPage } from './pages/LoadingPage';
import { useContactStore } from './store/contact';
import * as Sentry from '@sentry/react';
import { logger } from './logger';
import { getConfig } from './config';
import { FeatureFlagService } from './services/FeatureFlagService';

const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const NoJwtDataPage = lazy(() => import('./pages/NoJwtDataPage'));

/**
 * The App component is the root component, which wraps the entire checkout.
 * It's responsible for the initial auth flow, getting info about an existing session from Web,
 * or creating a new "anonymous" session when none exists.
 *
 * After the App has loaded the basic information the following store data will be complete:
 * - The {@link useAuthStore} contains valid credentials.
 * - The {@link useContactStore} will be loaded (note that the contact itself can still be undefined for anon sessions).
 *
 * After this, the {@link CheckoutPage} will be rendered.
 */
export const App: React.FC = () => {
  const auth = useAuthStore();
  const contact = useContactStore();
  const authService = useService(AuthService);
  const [error, setError] = useState(false);
  const [noJwtDataError, setNoJwtDataError] = useState(false);
  const featureFlagService = useService(FeatureFlagService);

  useEffect(() => {
    if (auth.accessToken || auth.loading) {
      return;
    }

    auth.setLoading();
    (async () => {
      let isSessionSet = false;
      try {
        //@todo: fix gitlab api access from frontend for external IP
        // const gitlabConfig = getConfig().gitlab;
        //
        // const flagStatus = await featureFlagService.getFeatureFlag(
        //   gitlabConfig.newAuthenticationFlag
        // );
        const flagStatus = getConfig().isNewAuthentication;
        auth.setIsNewAuth(flagStatus);

        isSessionSet = await authService.setUserSession(
          auth,
          contact,
          flagStatus
        );
      } catch (e) {
        Sentry.captureException(e);
        logger.error((e as Error).message ?? 'Failed to set user session', {
          sentry: true,
        });
        if (e instanceof NoJwtDataError) {
          setNoJwtDataError(true);
        }
      }

      setError(!isSessionSet);
    })();
  }, [authService, auth, contact, featureFlagService]);

  if (noJwtDataError) {
    return <NoJwtDataPage />;
  }

  if (error) {
    return <NotFoundPage />;
  }

  // Wait for the auth flow to be completed.
  if ((!auth.isNewAuth && !auth.accessToken) || contact.loading) {
    return <LoadingPage />;
  }

  return (
    <Routes>
      <Route path=":lang/:ticketId" element={<CheckoutPage />} />
      <Route path=":ticketId" element={<NoLanguageCheckoutRedirect />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const NoLanguageCheckoutRedirect: React.FC = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  return <Navigate to={`/en/${ticketId}`} replace />;
};
