import { getConfig } from '../config';
export class FeatureFlagService {
  public static readonly NAME = 'FeatureFlagService';

  public async getFeatureFlag(featureFlagName: string) {
    const gitlab = getConfig().gitlab;
    const response = await fetch(
      `${gitlab.apiUrl}/projects/${gitlab.projectId}/feature_flags/${featureFlagName}`,
      {
        headers: {
          Authorization: `Bearer ${gitlab.gitlabToken}`,
        },
      }
    );

    const data = await response.json();
    return !!data.active;
  }
}
